import React from "react"
import style from "./hero.module.scss"

const HeroImage = ({ writing, landscape, portrait }) => (
  <>
    <div className={style.image}>
      <picture>
        <source media="(orientation: portrait)" srcSet={portrait} />
        <source media="(orientation: landscape)" srcSet={landscape} />
        <img className={style.image} srcSet={landscape} alt={writing} />
      </picture>
      <div
        className={style.writing}
        dangerouslySetInnerHTML={{
          __html: writing && writing.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
        }}
      ></div>
    </div>
  </>
)

export default HeroImage
