import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/seo"
import style from "./press.module.scss"

const PressPage = ({ data }) => {
  const writing = data.allStrapiWriting.nodes[0].writing

  const [checkTag, setTag] = useState([])

  let tags = new Set()
  let presses = data.allStrapiPress.edges.map(e => e.node)
  for (const p of presses) {
    if (p.tag.length) {
      for (const t of p.tag) {
        if (t.tag) {
          tags.add(t.tag)
        }
      }
    }
  }

  if (checkTag.length > 0) {
    presses = presses.filter(p => {
      return [...p.tag].filter(pt => checkTag.indexOf(pt.tag) > -1).length > 0
    })
  }

  let tagsItems = []
  tags.forEach(v => {
    tagsItems.push(
      <div key={v} className={style.tagItem}>
        <input
          type="checkbox"
          id={v}
          value={v}
          onChange={e => {
            if (e.target.checked) {
              checkTag.push(e.target.value)
              setTag([...checkTag])
            } else {
              checkTag.splice(checkTag.indexOf(e.target.value), 1)
              setTag([...checkTag])
            }
          }}
        ></input>
        <label htmlFor={v}>{v}</label>
      </div>
    )
  })

  return (
    <Layout>
      <SEO title="報導" image={"/images/press-og-image.jpg"} />
      <HeroImage
        writing={writing || ""}
        portrait="/images/hero.press.portrait.svg"
        landscape="/images/hero.press.landscape.svg"
      />
      <div className={style.container}>
        <div className={style.tags}>{tagsItems}</div>
        <div className={style.list}>
          {presses.map((press, index) => {
            const title =
              press.title.length > 52
                ? press.title.substr(0, 52) + "..."
                : press.title
            const desc =
              press.description.length > 62
                ? press.description
                    .replace(/(?:\r\n|\r|\n)/g, " ")
                    .substr(0, 62) + "..."
                : press.description &&
                  press.description.replace(/(?:\r\n|\r|\n)/g, " ")
            return (
              <Link
                className={style.item}
                key={press.id}
                id={index}
                to={press.pressPage}
              >
                <div className={style.title}>
                  <div className={style.content}>
                    <h4 className={style.limit}>{title}</h4>
                    <div className={style.limit}>{desc}</div>
                  </div>
                </div>
                <div className={style.image}>
                  <img
                    alt={press.title}
                    src={press.og_image.childImageSharp.resize.src}
                  />
                </div>
                <div className={style.author}>
                  <div>
                    <img
                      alt={press.author.name.name}
                      src={press.author.avatar.childImageSharp.resize.src}
                    />
                  </div>
                  <div>
                    <h4>{press.author.name.name}</h4>
                    {new Intl.DateTimeFormat("zh-TW").format(
                      new Date(press.created_at)
                    )}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiWriting(filter: { key: { eq: "press_header" } }) {
      nodes {
        key
        writing
      }
    }
    allStrapiPress(
      filter: { site: { id: { eq: 2 } } }
      sort: { fields: created_at, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          pressPage: gatsbyPath(filePath: "/press/{StrapiPress.slug}")
          title
          description
          content
          author {
            avatar {
              childImageSharp {
                resize(width: 320, height: 320) {
                  src
                  width
                  height
                  aspectRatio
                }
              }
            }
            name {
              name
              origional_name
            }
          }
          og_image {
            childImageSharp {
              resize(width: 720, height: 540, cropFocus: CENTER) {
                src
              }
            }
          }
          tag {
            tag
          }
          created_at
        }
      }
    }
  }
`
export default PressPage
